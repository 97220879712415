import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import logo from '../images/logo.png'

const NotFoundPage = () => (
  <Layout>
    <div className="body thank-you">
      <div id="wrapperInner">
        <div className="logo">
          <img src={logo} alt=""></img>
        </div>
        <h1>PAGE NOT FOUND</h1>
        <p>You just hit a link that doesn&#39;t exist...</p>
        <Link to="/" className="btn">Go back to the homepage</Link>
      </div>
    </div>
    <div id="bg"></div>
  </Layout>
)

export default NotFoundPage
